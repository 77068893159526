/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { dateFormat } from '../../../../../auth'



export const ProductStartInfoCell: FC<any> = ({ product }) => {
  const startDate = product.user_plan_detail?.startDate
  if(!startDate){
    return <div className='symbol-label'>-</div>
  }
  return <div className='symbol-label'>{dateFormat(startDate)}</div>
}

export const ProductEndInfoCell: FC<any> = ({ product }) => {
  const endDate = product?.user_plan_detail?.endDate
  if(!endDate){
    return <div className='symbol-label'>-</div>
  }
  return <div className='symbol-label'>{dateFormat(endDate)}</div>
}
export const SubscriptionDateInfoCell: FC<any> = ({ product }) => {
  const endDate = product.created_at
  return <div className='symbol-label'>{dateFormat(endDate)}</div>
}

export const ProductStatusCell: FC<any> = ({ product }) => {
  const status = product.user_plan_detail?.isExpired

  if (status === "" || status === null || status === undefined) {
    return <div className='symbol-label'>-</div>
  }

  return (
    <div className='d-flex align-items-center'>
      <p>
        {status ? (
          <span className='badge badge-danger'>Expired</span>
        ) : (
          <span className='badge badge-success'>Active</span>
        )}
      </p>
    </div>
  )
}



